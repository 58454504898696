/* ------- footer ----  */
footer{
    /* margin-top: 5rem;     */
    /* background: #f3f3f3; */
    padding: 1rem 5rem;
    border-top: 1px solid #b9b7b7;
}
footer .flex.mr-2{
    display: flex;
    flex-direction: column;
    align-items:flex-start;
}

footer ul{
    padding-left: 0rem;
}
footer .col-md-3 .logo{
    width: 100px;
    margin-bottom: 2rem;
}
footer .col-md-3 p strong{
    color: var(--color-main);
    font-weight: 500;
}
footer .col-md-3 a{
    color: inherit;
    text-decoration: none;
}
footer .col-md-3 p a:hover{
    color: var(--color-main);
}
footer .col-md-3 .icon{
    color: var(--color-main);
    font-size: 1.2rem;
    margin-right: 0.5rem;
    margin-top: 0.5rem;
}
footer .col-md-3 .head{
    font-size: 1.2rem;
    color: var(--color-main);
    margin-bottom: 0.8rem;
    font-weight: 500;
}
footer .col-md-3 ul li{
    padding-bottom: 0.5rem;
    font-weight: 400;
}
footer .col-md-3 ul li a{
    transition: all 200ms ease-in;
}
footer .col-md-3 ul li a:hover{
    color: var(--color-main);
    margin-left: 0.8rem;
}
footer .copyright{
    border-top: 1px solid var(--color-main);
    padding-top: 1rem;
}
footer .copyright p{
    color: rgb(49, 46, 46);
}
footer .copyright a{
    color: var(--color-main);
}

@media only screen and (max-width: 700px){
    footer{
        padding: 1rem 3rem;
    }
}
@media only screen and (max-width: 550px){
    footer{
        padding: 1rem 1rem;
        margin-top: 1rem;
    }
}



/* --------------------------------------  */
.side-icons {
    position: fixed;
    right: 2%;
    top: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 500;
    gap: 1rem;
}
.side-icons .sin{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.3rem;
    color: white;
    font-size: 1.3rem;
    background: red;
    height: 2.8rem;
    width: 2.8rem;
    border-radius: 50%;
}
.side-icons .sin a{
    color: white;
    position: relative;
}

.side-icons .sin a::after{
    position: absolute;
    content: "Insta";
    color: white;
    padding: 0.1rem 0.5rem ;
    background: var(--color-main);
    right: -1000%;
    top: 5%;
    font-size: 1rem;
    border-radius: 5px;
    border-bottom-right-radius: 0rem;
    transition: var(--transition-3);
}
.side-icons .sin a.insta::after{
    content:"Instagram"
}
.side-icons .sin a.facebook::after{
    content:"Facebook"
}
.side-icons .sin a.youtube::after{
    content:"Youtube"
}
.side-icons .sin a.whatsapp::after{
    content:"Whatsapp"
}
.side-icons .sin a:hover{
    opacity: 0.9;
}
.side-icons .sin a:hover::after{
    right: 200%;
}
@media only screen and (max-width: 500px){
    .side-icons .sin{
        display: none;
    }
}





/* ------ upper-foot   ----  */
.upper-foot{
    border-top: 1px solid var(--color-main);
    padding-top: 1rem;
}
.upper-foot h5{
    color: var(--color-main);
    font-size: 1rem;
    text-transform: uppercase;
}
.upper-foot .imgs{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}
.upper-foot .imgs img{
    height: 40px;
    width: auto;
    object-fit: contain;
}
.upper-foot .end .imgs{
    justify-content: end;
}
.upper-foot .end .imgs img{
    height: 35px;
}
.upper-foot .end h5{
    text-align: end;
}




/* Media Querry ---  */
@media only screen and (max-width: 768px) {
    .upper-foot .end{
        margin-top: 1rem;
    }
    .upper-foot .end .imgs{
        justify-content: start;
    }
    .upper-foot .end .imgs img{
        height: 30px;
    }
    .upper-foot .end h5{
        text-align: start;
    }
}

@media only screen and (max-width: 500px){
    .upper-foot .end .imgs img {
        height: 30px;
    }
}





