/* Loading.css */
.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .spinner {
    width: 4rem;
    height: 4rem;
    border: 0.5rem solid rgba(0, 0, 0, 0.1);
    border-top: 0.5rem solid #000;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
  