.product-page .small-img {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

.product-page .big-img img {
    border: 1px solid cadetblue;
}

.product-page .small-img img {
    width: 100%;
    object-fit: contain;
}

/* ImageGallery.css */
.big-img {
    position: relative;
    overflow: hidden;
    width: 100%;
    max-width: 600px;
    /* Adjust as needed */
    cursor: none;
    /* Hide the cursor */
}

.big-img img {
    width: 100%;
}

.magnifier {
    position: absolute;
    border: 1px solid #000;
    height: 250px;
    /* Adjust as needed */
    width: 250px;
    /* Adjust as needed */
    background-repeat: no-repeat;
    pointer-events: none;
    display: none;
    /* Hide initially */
    z-index: 10;
}

.product-page .details h2 {
    font-size: 1.5rem;
    font-weight: 400;
    text-transform: capitalize;
    color: #0f1111;
    color: var(--color-main-light);
}

.product-page .details .line-det {
    margin-top: 0.5rem;
    padding: 0.5rem 0rem;
}

.product-page .pricing {
    display: flex;
    align-items: baseline;
    gap: 1rem;
}

.product-page .pricing h5,
.product-page .pricing .actual-price {
    font-size: 1.9rem;
    font-weight: 400;
    margin-bottom: 0rem;
}

.product-page .pricing .actual-price {
    color: var(--color-red-1);
}

.product-page .pricing .cut-price {
    text-decoration: line-through;
    font-size: 1.2rem;
    color: var(--color-grey-text);
}

.product-page .details .size-btns {
    margin: 0.6rem 0rem;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 1rem;
}

.product-page .details .size-btns button {
    outline: none;
    background: transparent;
    border: 1px solid var(--color-main);
    padding: 0.5rem 1rem;
    border-radius: 50rem;
}

.product-page .details .size-btns button.active {
    background: red;
    color: white;
}


.product-page .details .btns {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1rem;
    max-width: 60%;
}

.product-page .quantity-input {
    border: 2px solid var(--color-red-1);
    border-radius: 5px;
    display: grid;
    grid-template-columns: 20% 60% 20%;
    justify-items: center;
    align-items: center;

}

.product-page .quantity-input input {
    outline: none;
    border: none;
    text-align: center;
    background: transparent;
    color: var(--color-red-1);
}

.product-page .quantity-input i {
    background: var(--color-red-1);
    color: white;
    cursor: pointer;
    padding: 0.7rem;
    width: 100%;
}

.product-page .details .addToCart button {
    border: none;
    outline: none;
    padding: 0.5rem 1rem;
    width: 100%;
    text-align: center;
    background: var(--color-main);
    color: white;
    font-weight: 500;
    text-transform: uppercase;
}

.product-page .details table th {
    font-weight: 500;
    border-right: 1px solid #dee2e6;
}

@media only screen and (max-width: 1150px) {
    .product-page .details .btns {
        max-width: 100%;
    }
}

@media only screen and (max-width: 420px) {
    .product-page .details .btns {
        grid-template-columns: repeat(1, 1fr);
    }

    .product-page .details h2 {
        font-size: 1.2rem;
    }

    .product-page .pricing h5,
    .product-page .pricing .actual-price {
        font-size: 1.5rem;
        font-weight: 400;
    }
}






.new-button {
    display: inline-block;
    padding: 12px 24px;
    border: 1px solid var(--color-main);
    border-radius: 4px;
    transition: all 0.2s ease-in;
    position: relative;
    overflow: hidden;
    font-size: 19px;
    cursor: pointer;
    color: var(--color-main);
    z-index: 1;
    text-transform: uppercase;
    background: transparent;
}

.new-button:before {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%) scaleY(1) scaleX(1.25);
    top: 100%;
    width: 140%;
    height: 180%;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.new-button:after {
    content: "";
    position: absolute;
    left: 55%;
    transform: translateX(-50%) scaleY(1) scaleX(1.45);
    top: 180%;
    width: 160%;
    height: 190%;
    background-color: var(--color-main);
    border-radius: 50%;
    display: block;
    transition: all 0.5s 0.1s cubic-bezier(0.55, 0, 0.1, 1);
    z-index: -1;
}

.new-button:hover {
    color: #ffffff;
    border: 1px solid var(--color-main);
}

.new-button:hover:before {
    top: -35%;
    background-color: var(--color-main);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}

.button:hover:after {
    top: -45%;
    background-color: var(--color-main);
    transform: translateX(-50%) scaleY(1.3) scaleX(0.8);
}



