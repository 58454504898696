
/* ------- Order Confirm -------  */

.order-confirm{
    padding: 1.2rem 3rem;
    max-width: 1400px;
    margin: 0 auto;
}
.order-confirm .flex-row {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
}
.order-confirm .flex-row .icon{
    font-size: 2.5rem;
    padding-right: 1rem;
    color: var(--color-main);
}
.order-confirm .flex-row p{
    margin-bottom: 0rem;
}
.order-confirm .flex-row .content{
    font-size: 1.2rem;
    color: var(--color-red);
}

.order-confirm h4{
    color: var(--color-main);
    font-weight: 500;
    /* font-size: 1.2rem; */
    padding-bottom: 0.5rem;
}

.order-confirm .bordered-box{
    padding: 0.5rem;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgba(0, 19, 37, 0.08);
    margin-bottom: 1rem;
}
.order-confirm .bordered-box .table-inner .strong{
    font-weight: 500;
    color: var(--color-main);
}
.order-confirm .bordered-box .table-inner p{
    text-transform: capitalize;
}

.order-confirm .bordered-box .table-inner .col-md-6{
    padding-bottom: 1rem;
}

.order-confirm .cart-subtotal ul{
    padding-left: 0rem;
}
.order-confirm .cart-subtotal li {
    padding-bottom: 0.6rem;
}

.go-to{
    color: white;
    font-size: 1.2rem;
    text-decoration: underline;
    color: red;
}
.go-to:hover{
    font-weight: 500;
}

@media only screen and (max-width: 800px){
    .order-confirm{
        padding: 0rem 0rem;
    }
}





