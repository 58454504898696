
/* ------ ASIDE cart-side -----  */
.cart-side{
    position: fixed;
    top: 0;
    bottom: 0;
    right: -350px;
    background: white;
    z-index: 2000;

    width: 350px;
    border-left: 1px solid var(--color-grey);
    transition: all 300ms ease-in;
}
.cart-side.active{
    right: 0;
    transition: all 400ms ease-in;
}
.cart-side .cart-nav{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 8vh;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1rem;
    box-shadow: rgba(0, 19, 37, 0.16) 0px -1px 0px inset;
}

.cart-side .cart-nav a{
    font-size: 1.3rem;
    color: var(--color-red);
}
.cart-side .nav-logo{
    font-size: 1.5rem;
    margin-bottom: 0rem;
    color: var(--color-red);
}

.cart-footer{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8vh;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0rem 1rem;
    box-shadow: rgba(0, 19, 37, 0.16) 0px 1px 0px inset;
    z-index: 5;
}
.cart-footer .total-price{
    font-weight: 500;
}
.cart-footer .proceed{
    font-weight: 500;
    background: var(--color-red);
    font-size: 1.2rem;
    color: white;
    padding: 0.2rem 0.8rem;
    border-radius: 50rem;
}
.cart-main{
    margin: 8vh 0vh;
    height: 84vh;
    padding: 0.8rem;
    overflow-y: scroll;
}
.cart-main::-webkit-scrollbar {
    width: 3px;
}
.cart-main::-webkit-scrollbar-thumb {
    background: #888;
}

.cart-main .login-register{
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    background-color: rgb(255, 255, 255);
    border: 1.5px solid rgba(0, 19, 37, 0.08);
    padding: 0.5rem;
    border-radius: 5px;
    margin-bottom: 1rem;
}
.cart-main .login-register p{
    color: black;
    /* font-weight: 500; */
}
.cart-main .login-register a {
    padding: 0.2rem;
    border: 1px solid rgba(101, 119, 134, 0.36);
    background-color: rgb(255, 255, 255);
    color: rgb(232, 0, 113);
    border-radius: 8px;
    cursor: pointer;
    text-align: center;
    font-weight: 500;
}
.cart-main .login-register a:hover{
    background: rgb(232, 0, 113);
    background: var(--color-red);
    color: white;
}

/* ---- products-container ----  */
.products-container .single-product{
    /* padding: 0.5rem; */
    border: 1px solid rgba(101, 119, 134, 0.36);
    border-radius: 8px;
    margin: 1rem 0;
}
.products-container .single-product .product-desc ,
.products-container .single-product .price-qu {
    margin: 0.5rem;
}
.products-container .single-product .product-desc {
    margin: 0.5rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid rgba(101, 119, 134, 0.36);
}
.products-container .single-product .product-desc img{
    object-fit: contain;
}
.products-container .single-product .product-name{
    text-transform: capitalize;
    color: var(--color-black-light);
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
.products-container .single-product .size{
    text-transform: capitalize;
    padding: 0rem 0.2rem;
    border-radius: 2px;
    border: 1px solid var(--color-grey);
    font-size: 1rem;
}
.products-container .single-product .size{
    text-transform: capitalize;
    padding: 0rem 0.2rem;
    border-radius: 2px;
    border: 1px solid var(--color-grey);
}
.products-container .single-product #deleteProduct{
    color: var(--color-main);
    cursor: pointer;
}
.products-container .single-product .price-qu{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.products-container .single-product .price-qu .price{
    font-weight: 500;
}
.products-container .single-product .quantity-input{
    border: 2px solid var(--border-color-1);
}
.products-container .single-product .quantity-input{
    border: 2px solid var(--border-color-1);
    border-radius: 5px;
    /* padding: 1px; */
}
.products-container .single-product .quantity-input input{
    outline: none;
    border: none;
    width: 25px;
    text-align: center;
}
.products-container .single-product .quantity-input i{
    background: var(--color-light-weight);
    padding: 0.2rem;
    cursor: pointer;
}

.cart-main .price-details{
    padding: 0.5rem;
    border: 1px solid var(--border-color-1);
    border-radius: 8px;
}

.cart-main .price-details small.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.cart-main .price-details small.flex .cut-price{
    text-decoration: line-through;
    display: inline;
}
.cart-main .price-details small.flex .free{
    color: green;
}
.cart-main .price-details small.final{
    font-size: 1rem;
    font-weight: 500;
    color: var(--color-red);
}


.voucher-section{
    padding: 0.5rem;
    border: 1px solid var(--border-color-1);
    border-radius: 8px;
}
.voucher-input {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.input-voucher {
    flex: 1;
    padding: 5px;
    width: 100%;
    border: 2px solid #ccc;
    border-radius: 5px;
    outline: none;
    font-size: 0.9rem;
}
.input-voucher::placeholder{
    color: black;
}

.btn-apply-voucher {
    padding: 0.2rem 0.5rem;
    background-color: var(--color-red);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.9rem;
}

.voucher-error {
    color: red;
    margin-top: 10px;
    font-size: 0.8rem;
}

.voucher-success {
    color: green;
    margin-top: 10px;
    font-size: 0.8rem;
}